<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div>-->

    <div class="animated fadeIn" id="NotPrint">
      <div id="snackbar"></div>
      <b-card-header class="mb-3">
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>View/Print Ticket
          </b-col>
        </b-row>
      </b-card-header>
      <b-card>
        <div class="row">
          <b-col sm="6">
            <b-row>
              <b-col sm="3">
                <label>Search By :</label>
              </b-col>
              <b-col sm="4">
                <b-form-radio
                  v-model="viewPrintTicketFilter.searchBy"
                  name="status_option"
                  value="users"
                  class="mx-1"
                  checked
                  @change="getviewPrintTicketDetails"
                >Users Details</b-form-radio>
              </b-col>
              <b-col sm="4">
                <b-form-radio
                  v-model="viewPrintTicketFilter.searchBy"
                  name="status_option"
                  value="seats"
                  class="mx-1"
                  @change="getviewPrintTicketDetails"
                >Shows Details</b-form-radio>
              </b-col>
            </b-row>
          </b-col>
        </div>
        <div class="row">
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Name :</label>
              <b-form-input type="text" v-model="viewPrintTicketFilter.name" placeholder="Name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Booking ID :</label>
              <b-form-input
                type="text"
                v-model="viewPrintTicketFilter.bookingId"
                placeholder="Booking ID"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Email ID :</label>
              <b-form-input
                type="text"
                v-model="viewPrintTicketFilter.emailId"
                placeholder="Email ID"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Mobile No. :</label>
              <b-form-input
                type="text"
                v-model="viewPrintTicketFilter.mobileNumber"
                placeholder="Mobile No"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Transaction No. :</label>
              <b-form-input
                type="text"
                v-model="viewPrintTicketFilter.transactionNo"
                placeholder="Transaction No."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Seats No. :</label>
              <b-form-input
                type="text"
                v-model="viewPrintTicketFilter.seatsNo"
                placeholder="Seats No"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'users'">
            <b-form-group>
              <label for="from-date">Order ID. :</label>
              <b-form-input
                type="text"
                v-model="viewPrintTicketFilter.orderId"
                placeholder="Order ID."
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-------------seats search----------------------------------------->

          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'seats'">
            <b-form-group>
              <label for="cinema_name">Malls :</label>
              <b-form-select
                v-model="viewPrintTicketFilter.Cinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="$store.getters['common/getAllCinemas']"
                @change="getScreen"
              >
                <template slot="first">
                  <option :value="'0'">Select Malls</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'seats'">
            <b-form-group>
              <label for="from-date">Show Date:</label>
              <b-form-input type="date" v-model="viewPrintTicketFilter.showDate"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'seats'">
            <b-form-group>
              <label for="screen_name">Screen :</label>
              <b-form-select
                v-model="viewPrintTicketFilter.Screen"
                id="screen_name"
                :plain="true"
                text-field="ScreenName"
                value-field="ScreenID"
                :options="ScreenList"
                @change="getShowTime"
              >
                <!-- {{ ScreenList }} -->
                <template slot="first">
                  <option :value="'0'">Select Screen</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3" v-if="viewPrintTicketFilter.searchBy == 'seats'">
            <b-form-group>
              <label for="show_time">Show Time :</label>
              <b-form-select
                required
                v-model="viewPrintTicketFilter.ShowTime"
                id="show_time"
                :plain="true"
                text-field="ShowTime"
                value-field="ShowTime"
                :options="showTimeList"
              >
                <template slot="first">
                  <option :value="'0'">Select Show Time</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>
        <div class="row">
          <b-col sm="12" class="text-center">
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="getviewPrintTicketDetails"
            >Search</b-button>
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click.prevent="resetForm"
            >Reset</b-button>
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="ExportBookingExcel"
            >Export to Excel</b-button>
          </b-col>
        </div>
      </b-card>
      <hr />
      <b-card>
        <!-- Show Wise Report Setcion Start -->
        <div
          style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;"
          v-if="isLoader"
        >
          <CircleLoader />
        </div>
        <v-client-table
          v-if="viewPrintTicketFilter.searchBy == 'seats'"
          class="my-2"
          :data="data2"
          :columns="columns2"
          :options="options2"
        >
          <div slot="ShowDate" slot-scope="props">
            <span>{{ props.row.ShowDate }}</span>
          </div>
          <div slot="Prefix" slot-scope="props">
            <b-button
              v-if="props.row.isprint"
              @click="Print(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >Print</b-button>

            <b-button
              v-if="props.row.isresend"
              @click="resendEmailModal(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >Resend</b-button>
            <b-button
              @click="FullDetails(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >View</b-button>
          </div>
        </v-client-table>
        <!-- Show Wise Report Setcion End -->

        <!-- User Wise Report Setcion Start -->
        <v-client-table
          v-if="viewPrintTicketFilter.searchBy == 'users'"
          class="my-2"
          :data="data2"
          :columns="columns1"
          :options="options1"
        >
          <div slot="ShowDate" slot-scope="props">
            <span>{{ props.row.ShowDate }}</span>
          </div>
          <div slot="isprint" slot-scope="props">
            <b-button
              v-if="props.row.isprint"
              @click="Print(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >Print</b-button>

            <b-button
              v-if="props.row.isresend"
              @click="resendEmailModal(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >Resend</b-button>
            <b-button
              @click="FullDetails(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >View</b-button>
          </div>
          <div slot="isrefund" slot-scope="props">
            <b-button
              v-if="props.row.isrefund"
              @click="RefundModal(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
            >Refund</b-button>
          </div>
        </v-client-table>
        <!-- User Wise Report Setcion End -->

        <b-modal
          id="RefundDetails-modal"
          ref="RefundDetails-modal"
          :title="'Refund DETAILS'"
          size="lg"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
        >
          <div class="row">
            <b-col sm="12 row">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Total Amount. :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  disabled
                  type="text"
                  v-model="refundForm.actual_amount"
                  placeholder="Total Amount"
                ></b-form-input>
              </b-col>
            </b-col>
            <b-col sm="12 row mt-2">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Refund Amount. :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  type="text"
                  v-model="refundForm.refund_amount"
                  placeholder="Refund Amount"
                ></b-form-input>
              </b-col>
            </b-col>
            <b-col sm="12 row mt-2">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Refund Reason. :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-select
                  v-model="refundForm.refund_reason"
                  id="cinemas"
                  :plain="true"
                  text-field="CinemaName"
                  value-field="CinemaID"
                  :options="RefundReasonList"
                >
                  <template slot="first">
                    <option :value="'0'">Select Refund Reason</option>
                  </template>
                </b-form-select>
              </b-col>
            </b-col>
            <b-col sm="12 row mt-2">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Refund Mode. :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-select
                  v-model="refundForm.refund_mode"
                  id="cinemas"
                  :plain="true"
                  text-field="CinemaName"
                  value-field="CinemaID"
                  :options="RefundModeList"
                >
                  <template slot="first">
                    <option :value="'0'">Select Refund Mode</option>
                  </template>
                </b-form-select>
              </b-col>
            </b-col>
            <b-col sm="12 row mt-2">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Comments.:
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-textarea
                  type="text"
                  required
                  :maxlength="max_char"
                  v-model="refundForm.comment"
                  id="Comments"
                  placeholder="Enter Comments"
                ></b-form-textarea>
              </b-col>
            </b-col>
          </div>
          <hr />
          <b-button @click="hideRefundModal" variant="danger" class="pull-right ml-2 mt-2">Close</b-button>
          <b-button @click="UpdateRefund" variant="success" class="pull-right mt-2">Refund</b-button>
        </b-modal>

        <b-modal
          id="fullDetails-modal"
          ref="fullDetails-modal"
          :title="'FULL DETAILS'"
          size="xl"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
        >
          <div class="row">
            <div class="col-md-12 table-wrapper-scroll-y my-custom-scrollbar_xAxis">
              <table class="table mb-0 table-bordered">
                <tr>
                  <td>Movie Name</td>
                  <td>{{ singleDetails.MovieName }}</td>
                </tr>
                <tr>
                  <td>Show Details</td>
                  <td>
                    {{
                    singleDetails.ShowDate
                    ? singleDetails.ShowDate
                    : singleDetails.ShowDetails
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Screen</td>
                  <td>{{ singleDetails.ScreenName }}</td>
                </tr>
                <tr>
                  <td>Customer Name</td>
                  <td>{{ singleDetails.UserEmail }}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>
                    {{
                    singleDetails.CinemaChain
                    ? singleDetails.CinemaChain
                    : singleDetails.CinemaName
                    }}
                  </td>
                </tr>
                <tr>
                  <td>No.of Tickets</td>
                  <td>
                    {{
                    singleDetails.ViewNoofTicket
                    ? singleDetails.ViewNoofTicket
                    : singleDetails.NoOfTickets +
                    " ( " +
                    singleDetails.SeatNames +
                    " )"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Customer Email</td>
                  <td>
                    {{
                    singleDetails.UserEmail
                    ? singleDetails.UserEmail
                    : singleDetails.Email
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Prefix</td>
                  <td>{{ singleDetails.Prefix }}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>
                    {{
                    singleDetails.UserMobile
                    ? singleDetails.UserMobile
                    : singleDetails.Mobile
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Card Type</td>
                  <td>{{ singleDetails.CardType }}</td>
                </tr>
                <tr>
                  <td>Total Paid</td>
                  <td>{{ singleDetails.TotalPaid }}</td>
                </tr>
                <tr>
                  <td>Booking Timestamp</td>
                  <td>
                    {{
                    singleDetails.ShowTime
                    ? singleDetails.ShowTime
                    : singleDetails.ShowDetails
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Payment Gateway Forwarding Time</td>
                  <td>
                    {{singleDetails.ShowTime
                    ? singleDetails.ShowTime
                    : singleDetails.ShowDetails }}
                  </td>
                </tr>
                <tr>
                  <td>Transation Number</td>
                  <td>{{ singleDetails.TransSummaryID }}</td>
                </tr>
                <tr>
                  <td>Transation Time</td>
                  <td>{{ singleDetails.TransactionTime }}</td>
                </tr>
                <tr>
                  <td>Bank Status</td>
                  <td>{{ singleDetails.BankStatus }}</td>
                </tr>
                <tr>
                  <td>Order ID</td>
                  <td>{{ singleDetails.OrderID }}</td>
                </tr>
                <tr>
                  <td>Booking ID</td>
                  <td>{{ singleDetails.BookingID }}</td>
                </tr>
                <tr>
                  <td>Email Status</td>
                  <td>{{ singleDetails.Email_Status }}</td>
                </tr>
                <tr>
                  <td>SMS Status</td>
                  <td>{{ singleDetails.SMS_Status }}</td>
                </tr>
                <tr>
                  <td>QR Code</td>
                  <td>
                    <img :src="singleDetails.QRCode" class="img-fluid" alt />
                    <!-- {{singleDetails.QRCode}} -->
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <b-button @click="hidefullDetails" variant="danger" class="pull-right">Close</b-button>
        </b-modal>

        <b-modal
          id="resendEmailModal"
          ref="resendEmailModal"
          :title="'Resend Mail'"
          size="lg"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
        >
          <div class="row">
            <b-col sm="12 row">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Email Id :
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  type="text"
                  v-model="resendEmail.UserEmail"
                  placeholder="Enter Email Id"
                ></b-form-input>
              </b-col>
            </b-col>
            <b-col sm="12 row mt-3">
              <b-col sm="3" class="text-right">
                <label for="from-date">
                  Mobile Number:
                  <b class="text-danger">*</b>
                </label>
              </b-col>
              <b-col sm="9">
                <b-form-input
                  type="text"
                  v-model="resendEmail.UserMobile"
                  placeholder="Enter Mobile Number"
                ></b-form-input>
              </b-col>
            </b-col>
          </div>
          <hr />
          <b-button
            @click="hideresendEmailModal"
            variant="danger"
            class="pull-right ml-2 mt-2"
          >Close</b-button>
          <b-button @click="Resend" variant="success" class="pull-right mt-2">Resend</b-button>
        </b-modal>

        <b-button v-if="PrintHTML" size="sm" variant="primary" @click="ClearPage">Clear</b-button>
        <b-button v-if="PrintHTML" size="sm" variant="primary" @click="PrintPage">Print Ticket</b-button>
      </b-card>
    </div>
    <div id="printTicketDetails" class="row" v-if="PrintHTML">
      <div class="col-md-12" v-html="PrintHTML">{{ PrintHTML }}</div>
    </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import TicketService from "@/services/TicketService";
import AccountReportService from "@/services/AccountReportService";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../Component/SchedulesDetails/Circle.vue";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "BookingDetailsReport",
  components: {
    Event,
    cSwitch,
    // Loading,
    CircleLoader
  },
  watch: {},
  data: function() {
    return {
      isLoader: false,
      resendEmail: {
        UserEmail: "",
        UserMobile: "",
        ReserveID: ""
      },
      max_char: 256,
      refundForm: {
        OrderID: "",
        actual_amount: "",
        refund_amount: "",
        refund_reason: 0,
        refund_mode: 0,
        comment: ""
      },
      PrintHTML: "",
      cinemaList: [],
      singleDetails: "",
      viewPrintTicketFilter: {
        searchBy: "users",
        name: "",
        bookingId: "",
        emailId: "",
        mobileNumber: "",
        transactionNo: "",
        seatsNo: "",
        orderId: "",
        showDate: "",
        ToDate: "",
        Cinema: 0,
        Screen: 0,
        ShowTime: 0
      },
      RefundReasonList: [
        "Cancelled from box office due to COVID-19 Restrictions",
        "Cancelled from box office due to Age/Censor Restrictions",
        "As a good will from box-office for pleasing reason from customer",
        "Due to Time Restriction for Transaction in Payment Gateway Page",
        "Other"
      ],
      RefundModeList: [
        "Bank Transfer",
        "Request for Payment Gateway to refund",
        "Credit Card login direct refund",
        "Other"
      ],
      MovieList: [],
      ScreenList: [],
      showTimeList: [],

      //Book New Transcation
      columns2: [
        "Sr",
        "BookingID",
        "OrderID",
        "UserEmail",
        "ShowDate",
        "MovieName",
        "CinemaChain",
        "ScreenName",
        "SeatNames",
        "BookStatus",
        "Source",
        "Prefix"
      ],
      data2: [],
      finalArr: [],
      ExcelArr: [],
      options2: {
        headings: {
          Sr: "SN.",
          BookingID: "Booking ID",
          OrderID: "Order ID",
          UserEmail: "User Name",
          ShowDate: "Show Details",
          MovieName: "Movie Title",
          CinemaChain: "Cinema",
          ScreenName: "Screen",
          SeatNames: "Booked Seats",
          BookStatus: "Booked Result",
          // UserMobile: "IsScanned",
          Source: "Source",
          Prefix: "Actions"
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },

      columns1: [
        "BookingID",
        "OrderID",
        "UserName",
        "Mobile",
        "Email",
        "ShowDetails",
        "MovieName",
        "CinemaName",
        "ScreenName",
        "BookedSeats",
        "CardType",
        "TransactionResult",
        "BookedResult",
        "Source",
        "isprint",
        "isrefund"
      ],
      options1: {
        headings: {
          BookingID: "Booking ID",
          OrderID: "Order ID",
          UserName: "User Name",
          Mobile: "Mobile",
          Email: "Email",
          ShowDetails: "Show Details",
          MovieName: "Movie Title",
          CinemaName: "Cinema",
          ScreenName: "Screen",
          BookedSeats: "Booked Seats",
          // IsScanned: "Is Scanned",
          // Prefix: "Show Status",
          CardType: "Card Type",
          TransactionResult: "Transaction Result",
          BookedResult: "Booked Result",
          Source: "Source",
          isprint: "Actions",
          isrefund: "Refund Amount"
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {
    this.$store.dispatch("common/setAllCinemas", {});
    this.getFromToDate();
  },
  mounted() {
    this.getviewPrintTicketDetails();
    if (this.viewPrintTicketFilter.Cinema != 0) {
      this.getScreen();
    }
  },
  methods: {
    UpdateRefund() {
      if (
        this.refundForm.OrderID == "" ||
        this.refundForm.actual_amount == "" ||
        this.refundForm.refund_amount == "" ||
        this.refundForm.refund_reason == "" ||
        this.refundForm.refund_mode == "" ||
        this.refundForm.comment == ""
      ) {
        this.showMessageSnackbar("Please Fill All Details");
        return false;
      } else {
        if (this.refundForm.OrderID) {
          let payload = {
            OrderID: this.refundForm.OrderID
              ? parseInt(this.refundForm.OrderID)
              : 0,
            actual_amount: this.refundForm.actual_amount,
            refund_amount: this.refundForm.refund_amount,
            refund_reason: this.refundForm.refund_reason,
            refund_mode: this.refundForm.refund_mode,
            comment: this.refundForm.comment
          };
          console.log("payload=>", payload);
          TicketService.refundTransaction(payload)
            .then(response => {
              const { data } = response;
              console.log("response=>", response);
              if (data.Status) {
                this.showMessageSnackbar(data.Message);
                this.hideRefundModal();
              } else {
                this.showMessageSnackbar(data.Message);
              }
            })
            .catch(error => {
              console.log("Catch on AddUser-getRoles Error: ", error);
              this.showMessageSnackbar(
                "Oops! Something went wrong. kindly contact your administrator"
              );
            });
        }
      }
    },
    RefundModal(items) {
      this.refundForm.OrderID = items.ReserveID;
      this.refundForm.actual_amount = items.TotalPaid;
      this.refundForm.refund_amount = items.TotalPaid;
      this.$refs["RefundDetails-modal"].show();
    },
    hideRefundModal() {
      this.singleDetails = "";
      this.$refs["RefundDetails-modal"].hide();
    },
    FullDetails(items) {
      this.singleDetails = "";
      this.singleDetails = items;
      this.$refs["fullDetails-modal"].show();
    },
    hidefullDetails() {
      this.singleDetails = "";
      this.$refs["fullDetails-modal"].hide();
    },
    PrintPage() {
      window.print();
    },
    ClearPage() {
      this.PrintHTML = "";
    },
    resendEmailModal(items) {
      // console.log("Priyanshu SIngh=>",items)
      this.resendEmail.UserEmail = items.Email;
      this.resendEmail.UserMobile = items.Mobile;
      this.resendEmail.ReserveID = items.ReserveID;
      this.$refs["resendEmailModal"].show();
    },
    hideresendEmailModal() {
      this.resendEmail.UserEmail = "";
      this.resendEmail.UserMobile = "";
      this.resendEmail.ReserveID = "";
      this.$refs["resendEmailModal"].hide();
    },
    Resend() {
      if (
        this.resendEmail.ReserveID != "" &&
        this.resendEmail.UserEmail != ""
      ) {
        let payload = {
          Email: this.resendEmail.UserEmail,
          Mobile: this.resendEmail.UserMobile,
          OrderID: this.resendEmail.ReserveID
        };
        TicketService.resendMail(payload)
          .then(response => {
            const { data } = response;
            if (data.Status && data.Message) {
              this.showMessageSnackbar(data.Message);
              this.hideresendEmailModal();
            } else {
              this.showMessageSnackbar(data.Message);
            }
            this.getviewPrintTicketDetails();
          })
          .catch(error => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    Print(items) {
      if (
        items.ReserveID != undefined &&
        items.ReserveID != null &&
        items.ReserveID != ""
      ) {
        let payload = {
          OrderID: items.ReserveID
        };
        TicketService.prinTicket(payload)
          .then(response => {
            const { data } = response;
            if (data.Status && data.Message == "Data found") {
              this.PrintHTML = data.PrintHTML;
            } else {
              this.showMessageSnackbar(data.Message);
            }
            this.getviewPrintTicketDetails();
          })
          .catch(error => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.showDate = today;
      this.viewPrintTicketFilter.showDate = firstDay;
    },

    getScreen() {
      if (this.viewPrintTicketFilter.Cinema) {
        let payload = {
          CountryID: 113,
          CinemaID: this.viewPrintTicketFilter.Cinema
        };
        this.ScreenList = [];
        AccountReportService.getScreensByCinemaID(payload)
          .then(response => {
            const { data } = response;
            console.log(response);
            if (
              data.Status &&
              (data.ScreenList.length > 0 || data.ScreenList.length > 0)
            ) {
              this.ScreenList = data.ScreenList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    getShowTime() {
      if (this.viewPrintTicketFilter.Screen) {
        let payload = {
          CountryID: 113,
          MovieID: 0,
          CinemaID: this.viewPrintTicketFilter.Cinema,
          ScreenID: this.viewPrintTicketFilter.Screen,
          FromDate: this.viewPrintTicketFilter.showDate,
          ToDate: this.viewPrintTicketFilter.showDate
        };
        this.showTimeList = [];
        AccountReportService.getShowTimeByMovieID(payload)
          .then(response => {
            const { data } = response;
            if (data.Status && data.TimeList.length > 0) {
              this.showTimeList = data.TimeList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch(error => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    ExportBookingExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        let ExcelTiltle = "User_Details";
        if (this.viewPrintTicketFilter.searchBy == "seats") {
          ExcelTiltle = "Show_Details";
        } else {
          ExcelTiltle = "User_Details";
        }
        let ExcelDate = new Date().toDateString();
        if (this.viewPrintTicketFilter.showDate) {
          ExcelDate = new Date(
            this.viewPrintTicketFilter.showDate
          ).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, ExcelTiltle);
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_${ExcelTiltle}_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getviewPrintTicketDetails() {
      this.data2 = [];
      // this.PrintHTML = "";
      if (this.viewPrintTicketFilter.searchBy == "users") {
        this.getAllUserDetailShow();
      } else if (this.viewPrintTicketFilter.searchBy == "seats") {
        this.getAllShowDetails();
      }
    },
    getAllShowDetails() {
      this.data2 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;
      let payload = {
        CinemaID: this.viewPrintTicketFilter.Cinema,
        Date: this.viewPrintTicketFilter.showDate,
        ScreenID: this.viewPrintTicketFilter.Screen,
        ShowTime: this.viewPrintTicketFilter.ShowTime
      };

      TicketService.getAllShowDetails(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.ShowReportsDetails.Data.length > 0) {
            this.data2 = data.ShowReportsDetails.Data;
            if (this.data2.length > 0) {
              let finalArr = this.finalArr;
              let NewArr = this.data2.map(x => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Booking ID": x["BookingID"],
                  "Order ID": x["OrderID"],
                  "User Name": x["UserEmail"],
                  "Show Details": x["ShowDate"],
                  "Movie Title": x["MovieName"],
                  Cinema: x["CinemaChain"],
                  Screen: x["ScreenName"],
                  "Booked Seats": x["SeatNames"],
                  "Booked Result": x["BookStatus"],
                  Source: x["Source"]
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch(error => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getAllUserDetailShow() {
      this.data2 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;

      let payload = {
        Name: this.viewPrintTicketFilter.name,
        BookingID: this.viewPrintTicketFilter.bookingId,
        Email: this.viewPrintTicketFilter.emailId,
        Mobile: this.viewPrintTicketFilter.mobileNumber,
        transactionNo: this.viewPrintTicketFilter.transactionNo,
        NoofTicket: this.viewPrintTicketFilter.seatsNo,
        OrderID: this.viewPrintTicketFilter.orderId
      };

      TicketService.getAllUserDetailShow(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.Data.length > 0) {
            this.data2 = data.Data;
            console.log("this.data2=>", this.data2);
            if (this.data2.length > 0) {
              let finalArr = this.finalArr;
              let NewArr = this.data2.map(x => {
                finalArr.push({
                  "Booking ID": x["BookingID"],
                  "Order ID": x["OrderID"],
                  "User Name": x["UserName"],
                  Mobile: x["Mobile"],
                  Email: x["Email"],
                  "Show Details": x["ShowDetails"],
                  "Movie Title": x["MovieName"],
                  "Cinema Name": x["CinemaName"],
                  "Screen Name": x["ScreenName"],
                  "Booked Seats": x["BookedSeats"],
                  "Card Type": x["CardType"],
                  "Transaction Result": x["TransactionResult"],
                  "Booked Result": x["BookedResult"],
                  Source: x["Source"]
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch(error => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm() {
      Object.keys(this.viewPrintTicketFilter).forEach(
        key => (this.viewPrintTicketFilter[key] = "")
      );
      this.viewPrintTicketFilter["searchBy"] = "users";
      this.getFromToDate();
      this.viewPrintTicketFilter["Cinema"] = 0;
      this.viewPrintTicketFilter["Screen"] = 0;
      this.viewPrintTicketFilter["ShowTime"] = 0;
      this.viewPrintTicketFilter.name = "";
      this.viewPrintTicketFilter.bookingId = "";
      this.viewPrintTicketFilter.emailId = "";
      this.viewPrintTicketFilter.mobileNumber = "";
      this.viewPrintTicketFilter.transactionNo = "";
      this.viewPrintTicketFilter.seatsNo = "";
      this.viewPrintTicketFilter.orderId = "";
      this.PrintHTML = "";
      this.data2 = [];
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
label {
  font-weight: 600;
}
.my-custom-scrollbar {
  position: relative;
  height: 550px;
  overflow: auto;
}
.my-custom-scrollbar_xAxis {
  position: relative;
  height: 340px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
@media print {
  .printTicketDetails {
    display: block;
  }
  #non-print,
  #NotPrint {
    display: none;
  }
}
</style>
