import Api from './Api'

export default {
    getAllShowDetails(payload) {
        payload.Source="cineco",
        payload.organization_id="3"
        return Api().post('api/dashuser/FinanceShowDate', payload)
    },

    getAllUserDetailShow(payload) {
        payload.Source="cineco",
        payload.organization_id="3"
        return Api().post('admin/userdetailreport', payload)
    },

    resendMail(payload) {
        return Api().post('admin/resendtickets', payload)
    },
    prinTicket(payload) {
        return Api().post('admin/printticket', payload)
    },
    refundTransaction(payload){
        console.log("Priyanshu SIngh=>",payload)
        return Api().post('admin/refundtransaction', payload)
    },


}